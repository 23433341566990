import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import Icon from '@mui/material/Icon';

import useAccount from '@/hooks/useAccount';
import UserAPI from '@/network/UserAPI';

export default function ReadyToGo() {
  const { unhurdUser, refetchUser } = useAccount();
  const navigate = useNavigate();

  const handleNextClick = async () => {
    if (!unhurdUser) return;
    const details = {
      userId: unhurdUser?.userId,
      id: unhurdUser?.id,
      onboardingStage: 5,
    };
    try {
      await UserAPI.updateUserOnboarding({ auth0Id: unhurdUser?.userId, unhurdId: unhurdUser?.id, data: details });
      await refetchUser();
      navigate('/dashboard');
    } catch {
      return;
    }
  };

  return (
    <div className="mt120">
      <h1 className="">🎉 Great! Let’s get you started.</h1>
      <h3 className="text-faded mt32">You’re all setup and ready to go with your un:hurd music dashboard!</h3>
      <div className="pos-rel">
        <img src="/images/dashboard-preview.png" alt="email"></img>
        <Button className="btn-white view-dashboard-button" onClick={handleNextClick} data-testid="end-onboarding">
          <span className="btn-text icon-suffix">View my dashboard</span>
          <Icon>chevron_right</Icon>
        </Button>
      </div>
    </div>
  );
}
