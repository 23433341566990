import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { Icon } from '@mui/material';

import Logout from '../buttons/Logout';

export default function NavBar() {
  const navigate = useNavigate();
  const navItems = [
    { title: 'Dashboard', icon: 'dashboard', navLink: '/dashboard' },
    { title: 'Playlists', icon: 'playlists', navLink: '/playlists' },
    { title: 'Settings', icon: 'settings', navLink: '/settings' },
  ];

  const excludedPaths = [
    '/login',
    '/onboarding',
    '/spotify-callback',
    '/paypal-callback',
    '/add-playlist',
    '/payment-confirmation/playlist',
    '/payment-confirmation/subscription',
  ];

  if (excludedPaths.includes(location.pathname)) return null;

  return (
    <>
      <div className={'nav-container'}>
        <img
          className="cursor-pointer logo-img"
          src={'/images/logos/full-text-logo.svg'}
          alt="unhurd-logo"
          onClick={() => {
            navigate('/');
          }}
        ></img>

        <nav className="nav-sidebar">
          {navItems.map((item) => {
            return (
              <div key={item.title} className="min-h48" data-testid={`nav-bar-item-${item.icon}`}>
                <NavLink className={'nav-link-item'} to={item.navLink}>
                  {item.icon === 'playlists' ? (
                    <img className="nav-link-icon" src="/images/icons/PlaylistIcon.svg" alt=""></img>
                  ) : (
                    <Icon className="nav-link-icon material-symbols-outlined">{item.icon}</Icon>
                  )}

                  <span className={'nav-link-text'}>{item.title}</span>
                </NavLink>
              </div>
            );
          })}
        </nav>
        <div className={'nav-footer'}>
          <Logout />
        </div>
      </div>
    </>
  );
}
