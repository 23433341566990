import React from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from '@mui/material/Icon';

import useTodos from '@/hooks/useTodos';

export default function ToDo() {
  const { payPalConnected, hasPlaylists } = useTodos();

  const navigate = useNavigate();

  return (
    <>
      {(!hasPlaylists || !payPalConnected) && (
        <div className="to-do-card-border" data-testid="todo-card">
          <div className="to-do-card">
            <div className="card-title">
              <div className="card-icon material-icons">
                <div className="mt-6">👋</div>
              </div>
              <div className="titles">
                <p className="text-faded">Hi there,</p>
                <h3 className="text-number">Let's get you started</h3>
              </div>
            </div>
            {!hasPlaylists && (
              <div
                className="mt16 d-flex cursor-pointer"
                onClick={() => {
                  navigate('/add-playlist');
                }}
              >
                <Icon className="material-symbols-outlined text-faded">circle</Icon>
                <p className="pl16 small">
                  Add your playlists from your Spotify account. Once you have added these playlists, you can receive
                  pitches from different artists.
                </p>
                <Icon className="material-symbols-outlined text-faded ml-auto">chevron_right</Icon>
              </div>
            )}
            {!payPalConnected && (
              <div
                className="mt8 d-flex cursor-pointer"
                onClick={() => {
                  navigate('/settings', { state: 1 });
                }}
              >
                <Icon className="material-symbols-outlined text-faded">circle</Icon>
                <p className="pl16 small">
                  Setup your PayPal wallet in order to get paid from un:hurd music when accepting playlist pitches from
                  different artists.
                </p>
                <Icon className="material-symbols-outlined text-faded ml-auto">chevron_right</Icon>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
