import React, { useEffect, useState } from 'react';

import moment from 'moment';

import useReviewsOverTime from '@/hooks/metrics/useReviewsOverTime';
import { ReviewsOverTimeStatModel } from '@/models/Metrics';

import AreaChartComponent from '../charts/AreaChart';
import Loading from '../utility/Loading';

export default function ChartCard() {
  const [userHasPitches, setUserHasPitches] = useState<boolean>(true);
  const [chartData, setChartData] = useState<ReviewsOverTimeStatModel[]>([]);

  const { reviewsOverTime, totalReviewsOverTime, reviewsOverTimeIsLoading } = useReviewsOverTime();

  useEffect(() => {
    if (!reviewsOverTime) return;
    if (reviewsOverTime?.length === 0) {
      return setUserHasPitches(false);
    }
    if (reviewsOverTime.length === 1) {
      const initialPoint = {
        date: moment().subtract(1, 'days').format(),
        accepted: 0,
        rejected: 0,
        total: 0,
      };
      return setChartData([initialPoint].concat(reviewsOverTime));
    }
    setChartData(reviewsOverTime);
  }, [reviewsOverTime]);

  return (
    <>
      <div className="card flex-grow">
        <div className="d-flex jc-start gap16">
          <div className="card-icon material-icons">
            <img src="/images/icons/PitchingIcon.svg" alt=""></img>
          </div>
          <div className="titles">
            <div className="text-faded">Total pitches reviewed</div>
            <div className="text-number">{totalReviewsOverTime}</div>
          </div>
        </div>
        <div className="pos-rel">
          {reviewsOverTimeIsLoading && (
            <div className="centered-loading mt48">
              <Loading />
            </div>
          )}
          {!reviewsOverTimeIsLoading && chartData.length > 0 && (
            <div className="pt16 pb16">
              <AreaChartComponent data={chartData.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))} />
            </div>
          )}
          {!userHasPitches && (
            <div className="overlay-blur">
              <p className="text-faded mb16 mt100 pl16 pr16">
                It looks like you haven’t reviewed any pitches yet. Once you have reviewed pitches, they will appear
                here.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
