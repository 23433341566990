import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import Icon from '@mui/material/Icon';

import useAccount from '@/hooks/useAccount';

import { currencySymbol } from '../../formatters/CurrencyConverter';
import ProgressBar from '../utility/ProgressBar';

export default function TotalBalanceCard({ navButton }: { navButton?: boolean }) {
  const navigate = useNavigate();
  const [paid, setPaid] = useState<number>(0);
  const [pending, setPending] = useState<number>(0);
  const [paidProgress, setPaidProgress] = useState(0);
  const [pendingProgress, setPendingProgress] = useState(0);
  const { unhurdUser } = useAccount();

  useEffect(() => {
    if (unhurdUser) {
      setPaid(unhurdUser?.wallet?.paid || 0);
      setPending(unhurdUser?.wallet?.pending || 0);
    }
    const total = paid + pending;
    const percentage = 100 / total;
    setPaidProgress(percentage * paid);
    setPendingProgress(percentage * pending);
  }, [unhurdUser, pending, paid]);

  return (
    <div
      className="cursor-pointer card flex-w50p-8 flex-grow"
      onClick={() => {
        navigate('/dashboard/totalbalance');
      }}
    >
      <div className="card-title" data-testid="total-balance-card">
        <Icon className="card-icon">payments</Icon>
        <div className="titles">
          <div className="text-faded">Total balance</div>
          <div className="text-number">
            {unhurdUser?.wallet?.currency ? currencySymbol(unhurdUser?.wallet?.currency) : '£'}
            {(paid + pending).toFixed(2)}
          </div>
        </div>
        {navButton && (
          <Button
            className="icon-btn ml-auto"
            onClick={() => {
              navigate('/dashboard/totalbalance');
            }}
            data-testid="go-to-total-balance-button"
          >
            <Icon>chevron_right</Icon>
          </Button>
        )}
      </div>
      <div className="mt16">
        <ProgressBar
          progress={paidProgress}
          title="Paid"
          label={`${
            unhurdUser?.wallet?.currency ? currencySymbol(unhurdUser?.wallet?.currency) : '£'
          }${paid?.toFixed(2)}`}
        />

        <ProgressBar
          color="yellow"
          progress={pendingProgress}
          title="Owed"
          label={`${
            unhurdUser?.wallet?.currency ? currencySymbol(unhurdUser?.wallet?.currency) : '£'
          }${pending?.toFixed(2)}`}
        />
      </div>
    </div>
  );
}
