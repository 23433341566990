import React, { useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import Icon from '@mui/material/Icon';

import useAccount from '@/hooks/useAccount';
import UserAPI from '@/network/UserAPI';

import OnboardingContext, { OnboardingContextModel } from '../../contexts/OnboardingContext';
import PaypalLogin from '../utility/buttons/PaypalLogin';
import Loading from '../utility/Loading';

export default function WalletSetup() {
  const { dispatch } = useContext<OnboardingContextModel>(OnboardingContext);
  const { unhurdUser } = useAccount();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user } = useAuth0();

  useEffect(() => {
    if (!user || !user.sub) return;
    if (!unhurdUser) return;
    setIsLoading(false);
  }, [unhurdUser, user]);

  const doWalletLater = () => {
    dispatch({
      type: 'PAGE NEXT',
    });
  };

  const completeWallet = async () => {
    if (!unhurdUser) return;
    const details = {
      userId: unhurdUser?.userId,
      id: unhurdUser?.id,
      onboardingStage: 5,
    };

    UserAPI.updateUserOnboarding({ auth0Id: unhurdUser?.userId, unhurdId: unhurdUser?.id, data: details }).then(() => {
      dispatch({
        type: 'PAGE NEXT',
      });
    });
  };

  return (
    <div>
      <h1 className="">Setup your wallet</h1>
      <h3 className="text-faded mt32">Setup your wallet using PayPal in order to get paid from un:hurd music.</h3>
      <img className="mt48" src="/images/paypal-logo.svg" alt="email"></img>
      <div></div>
      {!isLoading && !unhurdUser?.paymentDetails?.payPal?.email ? (
        <div className="mt32">
          {' '}
          <PaypalLogin page="onboarding" />
        </div>
      ) : (
        <div>
          {!isLoading && (
            <div className="text-faded mt32">
              PayPal account <span className="text-white">{unhurdUser?.paymentDetails?.payPal?.email}</span> added{' '}
              <span>
                <img src="/images/icons/VerifiedIcon.svg" alt=""></img>
              </span>
            </div>
          )}
        </div>
      )}
      {isLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      <div className="onboarding-nav-container">
        <div className="onboarding-nav-buttons">
          <div className="do-later-button">
            <Button
              className="mt32"
              onClick={() => {
                doWalletLater();
              }}
            >
              <span className="btn-text">Do this later</span>
            </Button>
          </div>

          <div className="ml-auto">
            <Button
              className="mt32"
              onClick={() => {
                dispatch({
                  type: 'PAGE BACK',
                });
              }}
              data-testid="do-it-later-button"
            >
              <Icon className="icon-prefix">chevron_left</Icon>
              <span className="btn-text icon-prefix">Go back</span>
            </Button>

            <Button
              className="mt32 btn-white"
              onClick={() => {
                completeWallet();
              }}
              data-testid="continue-button"
            >
              <span className="btn-text icon-suffix">Continue</span>
              <Icon>chevron_right</Icon>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
