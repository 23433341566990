import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@mui/material';

import usePendingPitchesStats from '@/hooks/metrics/usePendingPitchesStats';

export default function PendingPitchesCard() {
  const { distinctArtists, distinctTracks, pendingPitches } = usePendingPitchesStats();

  const navigate = useNavigate();

  return (
    <>
      <div
        className="card cursor-pointer flex-w50p-8"
        onClick={() => {
          navigate('pendingpitches');
        }}
      >
        <div className="card-title">
          <div className="card-icon material-icons">
            <img src="/images/icons/PitchingIcon.svg" alt=""></img>
          </div>
          <div className="titles">
            <div className="text-faded">Pending pitches</div>
            <div className="text-number">{pendingPitches}</div>
          </div>
          <Button
            className="icon-btn ml-auto"
            onClick={() => {
              navigate('pendingpitches');
            }}
            data-testid="go-to-pending-pitches-button"
          >
            <Icon>chevron_right</Icon>
          </Button>
        </div>
        {pendingPitches === 0 && (
          <div className="text-faded mt16">
            It looks like you have <span className="text-white">{pendingPitches}</span> pitches to review. Once you
            receive pitches from artists at un:hurd music, they will appear here.
          </div>
        )}
        {pendingPitches && pendingPitches > 0 && (
          <div className="text-faded mt16">
            You have <span className="text-white">{distinctTracks}</span> distinct tracks to review, by{' '}
            <span className="text-white">{distinctArtists}</span> different artists, in{' '}
            <span className="text-white">{pendingPitches}</span> separate pitches. Get reviewing to get paid!
          </div>
        )}
      </div>
    </>
  );
}
