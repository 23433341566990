import * as React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Icon from '@mui/material/Icon';

import AuthAPI from '@/network/AuthAPI';

interface ILogoutProps {
  minimize?: boolean;
}

export default function Logout({ minimize }: ILogoutProps) {
  const { logout } = useAuth0();
  return (
    <div
      className={minimize ? 'logout-button minimize' : 'logout-button'}
      onClick={() => {
        AuthAPI.removeAllTokens();
        logout({ logoutParams: { returnTo: window.location.origin } });
      }}
      data-testid="sign-out-button"
    >
      <Icon className="logout-icon">logout</Icon>
      {!minimize && <span className="logout-text"> Sign out</span>}
    </div>
  );
}
