import React, { ComponentType } from 'react';

export const Icons: Record<string, ComponentType> = {
  card: () => (
    <svg id="card-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.75" y="2.75" width="14.5" height="11.5" rx="1.25" stroke="#99989f" strokeWidth="1.5" />
      <path d="M4 17.25L16 17.25" stroke="#99989f" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  ),
  list: () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="3" width="12" height="2" rx="1" fill="#99989f" />
      <rect x="6" y="9" width="12" height="2" rx="1" fill="#99989f" />
      <rect x="6" y="15" width="12" height="2" rx="1" fill="#99989f" />
      <circle cx="3" cy="4" r="1" fill="#99989f" />
      <circle cx="3" cy="10" r="1" fill="#99989f" />
      <circle cx="3" cy="16" r="1" fill="#99989f" />
    </svg>
  ),
};
