import React, { useEffect, useRef, useState } from 'react';

import SortIcon from '@mui/icons-material/Sort';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';

import usePlayTrackPreview from '@/hooks/usePlayTrackPreview';

import { dateTimeFormatter } from '../../formatters/DateTimeFormatter';
import { durationFormatter } from '../../formatters/DurationFormatter';
import { ColumnsModel, TrackPlayingIndex } from '../../models/PlaylistInterface';
import { pitchTrackArtist, PitchTrackModel } from '../../models/TrackInterfaces';
import FeedbackModal from '../modals/FeedbackModal';

export interface IPitchesTableProps {
  tracks: PitchTrackModel[];
  showPlaylist?: boolean;
  playlistId?: string;
  sort?: string;
  orderBy?: 'desc' | 'asc';
  playingIndex?: TrackPlayingIndex;
  selectedTracks: PitchTrackModel[];
  setSelectedTracks: (tracks: PitchTrackModel[]) => void;
  updateTracks: () => void;
  pageNumber?: number;
  bottomTrackIntersecting?: (intersecting: IntersectionObserverEntry[]) => void;
  updateTracksSortBy?: (sortBy: { sortBy: string; orderBy: 'desc' | 'asc' }) => void;
}

export default function PitchesTable({
  tracks,
  sort,
  orderBy,
  selectedTracks,
  showPlaylist,
  setSelectedTracks,
  updateTracks,
  pageNumber,
  bottomTrackIntersecting,
  updateTracksSortBy,
}: IPitchesTableProps) {
  const columns: ColumnsModel[] = [
    { name: '', id: 'checkbox' },
    { name: '#', id: 'number' },
    { name: 'Title', id: 'trackName' },
    { name: 'Artist', id: 'artistName' },
    showPlaylist ? { name: 'Playlist', id: 'playlistName' } : { name: 'Album', id: 'albumName' },
    { name: 'Duration', id: 'duration' },
    { name: 'Date/Time', id: 'date' },
    { name: '', id: '' },
  ];
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedTrack, setSelectedTrack] = useState<PitchTrackModel[]>([]);
  const [accept, setAccept] = useState<boolean>();

  const { trackPreviewUrl, setTrackPreviewUrl } = usePlayTrackPreview();

  const [sortBy, setSortBy] = useState<string>(sort || 'date');
  const [order, setOrder] = useState<'desc' | 'asc'>(orderBy || 'desc');

  const ref = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    return () => {
      setTrackPreviewUrl(undefined);
    };
  }, [setTrackPreviewUrl]);

  const isChecked = (event: PitchTrackModel) => {
    const result = selectedTracks.filter((item: PitchTrackModel) => item === event);
    if (result.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckboxChange = (event: PitchTrackModel) => {
    const result = selectedTracks.filter((item: PitchTrackModel) => item === event);
    if (result.length > 0) {
      const checked = selectedTracks.filter((item: PitchTrackModel) => item !== event);
      setSelectedTracks(checked);
    } else {
      setSelectedTracks([...selectedTracks, event]);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (tracks && tracks.length > 0) {
      const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        if (entries[0].isIntersecting) {
          if (bottomTrackIntersecting) {
            bottomTrackIntersecting(entries);
          }
        }
      }, {});
      if (ref.current) {
        observer.observe(ref.current);
      }
    }
  }, [bottomTrackIntersecting, tracks]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <colgroup>
            <col width="1%" />
            <col width="1%" />
            <col width="20%" />
            <col width="20%" />
            <col width="15%" />
            <col width="5%" />
            <col width="10%" />
            <col width="20%" />
          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={sortBy === column.id}
                    disabled={
                      (!showPlaylist && column.id !== 'checkbox') ||
                      column.id === '' ||
                      column.id === 'duration' ||
                      column.id === 'albumName' ||
                      column.id === 'number' ||
                      column.id === 'artistName'
                    }
                    direction={order}
                    onClick={() => {
                      if (!updateTracksSortBy) return;
                      updateTracksSortBy({ sortBy: column.id, orderBy: order === 'desc' ? 'asc' : 'desc' });
                      setSortBy(column.id);
                      setOrder(column.id === sortBy ? (order === 'desc' ? 'asc' : 'desc') : order);
                    }}
                    IconComponent={SortIcon}
                  >
                    {column.id === 'checkbox' && !showPlaylist ? (
                      <div className="pl8">
                        <div
                          className="checkbox-icon"
                          onClick={() => {
                            if (selectedTracks.length > 0) {
                              setSelectedTracks([]);
                            } else {
                              setSelectedTracks(tracks);
                            }
                          }}
                        >
                          {selectedTracks.length === tracks.length && <div className="checked"></div>}
                        </div>
                      </div>
                    ) : (
                      column.name
                    )}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tracks?.map((row: PitchTrackModel, index: number) => (
              <TableRow
                ref={index === tracks.length - 1 ? ref : null}
                data-testid={`pitch-item-row-${index}`}
                key={index}
                className={trackPreviewUrl === row.track.previewUrl ? 'item-playing' : ''}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '--animation-number': index % 20,
                }}
              >
                <TableCell className="no-overflow">
                  <FormControlLabel
                    label=""
                    control={
                      <Checkbox
                        id="playlist-checkbox"
                        name="playlist-checkbox"
                        icon={<div className="checkbox-icon"></div>}
                        checkedIcon={
                          <div className="checkbox-icon">
                            <div className="checked"></div>
                          </div>
                        }
                        checked={isChecked(row)}
                        onChange={() => {
                          handleCheckboxChange(row);
                        }}
                        value={row.track.spotifyId}
                      />
                    }
                  />
                </TableCell>
                <TableCell className="no-overflow">{pageNumber ? pageNumber * 10 + index + 1 : index + 1}</TableCell>
                <TableCell>
                  <div
                    className="table-item-with-image cursor-pointer"
                    onClick={() => {
                      window.open(row.track.url);
                    }}
                  >
                    <img src={row.track?.image ? row.track?.image : 'images/logos/no-image-available.svg'} alt="" />
                    <span>{row.track?.name}</span>
                  </div>
                </TableCell>
                <TableCell width="20%">
                  {row.track?.artists?.map((artist: pitchTrackArtist, index: number) =>
                    index === row.track?.artists.length - 1 ? artist.name : artist.name + ', '
                  ) || 'N/A'}
                </TableCell>
                <TableCell>{showPlaylist ? row.playlist.name : row.track?.albumTitle || 'N/A'}</TableCell>
                <TableCell>{durationFormatter(row.track?.duration, 'msToMM:SS')}</TableCell>
                <TableCell>{dateTimeFormatter(row.createdAt)}</TableCell>
                <TableCell className="no-overflow">
                  <div className="table-end-call-with-button">
                    {row.track.previewUrl && row.track.previewUrl !== '' && (
                      <Tooltip title="Preview" arrow placement="top">
                        <Button
                          className={`icon-btn`}
                          onClick={() => {
                            if (trackPreviewUrl === row.track.previewUrl) {
                              setTrackPreviewUrl(undefined);
                              return;
                            }
                            setTrackPreviewUrl(row.track.previewUrl);
                          }}
                        >
                          {trackPreviewUrl !== row.track.previewUrl && <Icon className={`text-white`}>play_arrow</Icon>}
                          {trackPreviewUrl === row.track.previewUrl && <Icon className={'text-white'}>pause</Icon>}
                        </Button>
                      </Tooltip>
                    )}
                    {!row.track.previewUrl && (
                      <Tooltip title="No Preview Available" arrow placement="top">
                        <Button className="icon-btn">
                          <Icon className={`text-faded`}>do_not_disturb</Icon>
                        </Button>
                      </Tooltip>
                    )}
                    {row.track.url && row.track.url !== '' && (
                      <Tooltip title="View on Spotify" arrow placement="top">
                        <Button
                          className="icon-btn ml0"
                          onClick={() => {
                            window.open(row.track.url);
                          }}
                        >
                          <img src="/images/spotify-logo.png" alt="spot-logo"></img>
                        </Button>
                      </Tooltip>
                    )}

                    <Button
                      className="border-btn"
                      onClick={() => {
                        setSelectedTrack([row]);
                        setAccept(false);
                        setModalOpen(true);
                      }}
                    >
                      <span className="btn-text icon-suffix">Reject</span>
                      <Icon>close</Icon>
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedTrack([row]);
                        setAccept(true);
                        setModalOpen(true);
                      }}
                      className="btn-white"
                    >
                      <span className="btn-text icon-suffix">Accept</span>
                      <Icon>done</Icon>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <React.Fragment>
          <FeedbackModal
            tracks={selectedTrack}
            accept={accept}
            closeModalOutput={setModalOpen}
            updateTracks={updateTracks}
          ></FeedbackModal>
        </React.Fragment>
      </Modal>
    </>
  );
}
