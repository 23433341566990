import React from 'react';

import { Pie, PieChart, Tooltip, TooltipProps } from 'recharts';

import Loading from '../utility/Loading';

export default function PieChartComponent({
  playlistData,
  total,
}: {
  playlistData: unknown[] | undefined;
  total?: number;
}) {
  const handleTooltip = (e: TooltipProps<number | string | Array<number | string>, string | number>) => {
    if (!e.payload || !e.payload[0]) return;
    return (
      <div className="text-center pie-tooltip">
        <p className="small">
          {e.payload[0]?.name}: {e.payload[0]?.value}
        </p>
      </div>
    );
  };

  return (
    <>
      <div data-testid="dashboard-pie-chart">
        {playlistData && (
          <PieChart width={300} height={250} className="pie-chart-wrapper">
            <defs>
              <linearGradient id="blue-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#406bff" />
                <stop offset="100%" stopColor="#06befe" />
              </linearGradient>
              <linearGradient id="yellow-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#ffae18" />
                <stop offset="100%" stopColor="#fdd07a" />
              </linearGradient>
              <linearGradient id="pink-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#f8005e" />
                <stop offset="100%" stopColor="#f8629b" />
              </linearGradient>
            </defs>

            <Pie
              data={playlistData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={110}
              fill="url(#blue-gradient)"
              stroke="none"
              animationDuration={1000}
              animationBegin={0}
            />

            <Tooltip content={handleTooltip} />
          </PieChart>
        )}

        {playlistData && (
          <div className="pie-chart-center-text">
            <p className="text-faded">Playlists</p>
            <h3>{total}</h3>
          </div>
        )}
        {!playlistData && (
          <div className="centered-loading mt48 mb48" data-testid="pie-loading">
            <Loading size="small" />
          </div>
        )}
      </div>
    </>
  );
}
