import React from 'react';

import { Icon, Tooltip } from '@mui/material';

type Status = 'verified' | 'pending' | 'rejected' | 'accepted';

export interface IStatusProps {
  status: Status | number;
  type?: string;
  isPaused?: boolean;
}

export default function StatusBadges({ status, type, isPaused }: IStatusProps) {
  if (isPaused) {
    return (
      <div className="status-badge pending pt4">
        {/* <Icon>task_alt</Icon> */}
        <Icon>pause</Icon>
        <span className="pt2 pl4 pr4">Paused</span>
        <Tooltip title="This playlist is in holiday mode and will not receive any submissions" arrow placement="right">
          <Icon className="material-symbols-outlined">info</Icon>
        </Tooltip>
      </div>
    );
  }
  return (
    <>
      {(status === 'verified' || status === 2) && (
        <div className="status-badge verified pt4">
          {/* <Icon>task_alt</Icon> */}
          <img src="/images/icons/VerifiedIcon.svg" alt=""></img>
          <span className="pt2 pl4 pr4">{type === 'transaction' ? 'Successful' : 'Verified'}</span>
          <Tooltip title="This playlist is verified and can receive pitches" arrow placement="right">
            <Icon className="material-symbols-outlined">info</Icon>
          </Tooltip>
        </div>
      )}
      {(status === 'pending' || status === 1) && (
        <div className="status-badge pending pt4">
          <Icon>schedule</Icon>
          <span className="pt2 pl4 pr4">Pending</span>
          <Tooltip
            title="We're reviewing your playlist submission and will get back to your shortly"
            arrow
            placement="right"
          >
            <Icon className="material-symbols-outlined">info</Icon>
          </Tooltip>
        </div>
      )}
      {(status === 'rejected' || status === 0) && (
        <div className="status-badge rejected pt4">
          <Icon className="material-symbols-outlined">warning</Icon>
          <span className="pt2 pl4 pr4">{type === 'transaction' ? 'Failed' : 'Rejected'}</span>
          <Tooltip title="This playlist has been rejected and will not receive any pitches" arrow placement="right">
            <Icon className="material-symbols-outlined">info</Icon>
          </Tooltip>
        </div>
      )}
      {status === 'accepted' && (
        <div className="status-badge verified pt4">
          {/* <Icon>task_alt</Icon> */}
          <img src="/images/icons/VerifiedIcon.svg" alt=""></img>
          <span className="pt2 pl4 pr4">{type === 'transaction' ? 'Successful' : 'Accepted'}</span>
          <Tooltip
            title={type === 'transaction' ? 'This transaction was successful' : 'You accepted this pitch'}
            arrow
            placement="right"
          >
            <Icon className="material-symbols-outlined">info</Icon>
          </Tooltip>
        </div>
      )}
    </>
  );
}
