import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';
import Icon from '@mui/material/Icon';

import useAccount from '@/hooks/useAccount';

import { averageTimeConverter } from '../../../formatters/DurationFormatter';
import Loading from '../Loading';

export default function UserHeader({ isAddPlaylist }: { isAddPlaylist?: boolean }) {
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { unhurdUser } = useAccount();

  useEffect(() => {
    if (unhurdUser) {
      setIsUserLoading(false);
    }
  }, [unhurdUser]);

  return (
    <>
      {!isUserLoading ? (
        <div className="dashboard-header">
          {unhurdUser?.spotifyAccounts && (
            <div className="dashboard-banner">
              <div className="pos-rel">
                <img
                  className="profile-pic"
                  src={
                    unhurdUser?.spotifyAccounts[0].images && unhurdUser?.spotifyAccounts[0].images.length > 0
                      ? unhurdUser?.spotifyAccounts[0].images[unhurdUser?.spotifyAccounts[0].images.length - 1]?.url
                      : '/images/profile-placeholder.svg'
                  }
                  alt="profile-pic"
                  data-testid="user-header-picture"
                ></img>
                <img className="verified-badge pos-abs" src="/images/icons/VerifiedIcon.svg" alt=""></img>
              </div>

              <div className="dashboard-banner-text">
                <h2 data-testid="user-header-display-name">{unhurdUser?.spotifyAccounts[0].displayName}</h2>
                <p data-testid="user-header-followers">
                  Followers: <span className="text-faded">{unhurdUser?.spotifyAccounts[0].followers}</span>
                </p>
                <p data-testid="user-header-response-time">
                  Average response time (All time):{' '}
                  <span className="text-faded">
                    {unhurdUser?.stats?.avgAllTimeResponseTime
                      ? averageTimeConverter(unhurdUser?.stats?.avgAllTimeResponseTime)
                      : 'N/A'}
                  </span>
                </p>
              </div>
            </div>
          )}
          {isAddPlaylist && (
            <Button
              onClick={() => {
                navigate('/add-playlist');
              }}
              className="btn-white min-w180 mt48 mr0"
              data-testid="add-new-playlist-button"
            >
              <span className="btn-text icon-suffix">Add new playlist</span>
              <Icon>add</Icon>
            </Button>
          )}
        </div>
      ) : (
        <div className="dashboard-header centered-loading">
          <Loading size="small" />
        </div>
      )}
    </>
  );
}
