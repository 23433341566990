import React, { Fragment, SyntheticEvent, useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import AppContext, { SnackbarContextModel } from '../../contexts/AppContext';

export default function SnackbarComponent() {
  const [open, setOpen] = useState(false);
  const { snackbar, dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);

  useEffect(() => {
    if (snackbar.snackbar?.open) {
      setOpen(snackbar.snackbar.open);
    } else {
      setOpen(false);
    }
  }, [snackbar]);

  const handleClose = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    dispatchSnackbar({ type: 'CLOSE_SNACKBAR' });
  };

  return (
    <>
      <Snackbar
        className={`snackbar-color-${snackbar.snackbar?.type}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={
          <div className="d-flex">
            {snackbar.type === 'success' ? (
              <Icon>task_alt</Icon>
            ) : snackbar.type === 'error' ? (
              <Icon className="material-symbols-outlined">error</Icon>
            ) : (
              <Icon className="material-symbols-outlined">info</Icon>
            )}
            <span className="pl16 pt2"> {snackbar.snackbar?.message}</span>
          </div>
        }
        action={
          <Fragment>
            <IconButton
              className="icon-btn no-bg"
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
      ></Snackbar>
    </>
  );
}
