import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';

import { User } from '@/models/UserInterfaces';
import UserAPI from '@/network/UserAPI';

import OnboardingContext, { OnboardingContextModel } from '../../contexts/OnboardingContext';
import Logout from '../utility/buttons/Logout';
import Loading from '../utility/Loading';

export default function VerifyEmail() {
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
  const { user, isLoading } = useAuth0();
  const { dispatch } = useContext<OnboardingContextModel>(OnboardingContext);

  const resendEmail = async () => {
    if (!user?.sub) return;
    try {
      await UserAPI.resendEmailVerification({ auth0Id: user.sub });
    } catch {
      // do nothing
    }
  };

  const createUser = useCallback(() => {
    if (!user || !user.sub || !user.email) return;
    const data = {
      userId: user.sub,
      authenticationType: 0,
      email: user.email,
      firstName: user.given_name || '',
      lastName: user.family_name || '',
    };
    UserAPI.createUser(data).then((resp: AxiosResponse<User>) => {
      localStorage.setItem('userID', resp.data.userId);
      localStorage.setItem('unhurdID', resp.data.id);
      dispatch({
        type: 'PAGE NEXT',
      });
      setIsLoadingUser(false);
    });
  }, [dispatch, user]);

  const checkUser = useCallback(async () => {
    if (!user || !user.sub) return;
    if (user?.email_verified) {
      try {
        const data = await UserAPI.getUserByID({ id: user.sub });
        if (data.data === undefined) {
          createUser();
        } else if (data.data) {
          dispatch({
            type: 'PAGE NEXT',
          });
          setIsLoadingUser(false);
        }
      } catch (err: unknown) {
        if (err === typeof AxiosError && (err as AxiosError)?.response?.status === 404) {
          createUser();
        }
        setIsLoadingUser(false);
      }
    } else {
      setIsLoadingUser(false);
    }
  }, [createUser, dispatch, user]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <>
      {!isLoadingUser && (
        <div className="text-center mt120" data-testid="verify-email-component">
          <h1 className="">Verify your email address</h1>
          <h3 className="text-faded mt32">Before we get started, we just need you to verify your email address.</h3>
          <h3 className="text-faded">
            We’ve sent a code to <span className="text-blue">{user?.email}</span>
          </h3>
          <img className="mt48" src="/images/email-icon.svg" alt="email"></img>
          <div></div>
          <Button className="btn-white mt32" onClick={resendEmail}>
            Resend verification email
          </Button>
          <div className="onboarding-nav-container">
            <div className="onboarding-nav-buttons">
              <div className="ml-auto">
                <Logout />
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="centered-loading mt48" data-testid="verify-email-loading">
          <Loading />
        </div>
      )}
    </>
  );
}
