import React, { useContext, useEffect } from 'react';

import OnboardingContext, { OnboardingContextModel } from '../../contexts/OnboardingContext';
import Loading from '../utility/Loading';

export default function SettingUp() {
  const { dispatch } = useContext<OnboardingContextModel>(OnboardingContext);

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: 'PAGE NEXT',
      });
    }, 1000);
  }, [dispatch]);

  return (
    <div className="mt120">
      <h1 className="">Setting you up...</h1>
      <h3 className="text-faded mt32">Hang on a sec whilst we set you up with your new dashboard.</h3>
      <div className="centered-loading mt48">
        <Loading />
      </div>
    </div>
  );
}
