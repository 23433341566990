import React, { useCallback, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import TrackPreviewProvider from '@/contexts/TrackPreviewContext';

import useAccount from '../../../hooks/useAccount';
import Loading from '../Loading';
import Footer from '../navigation/Footer';
import NavBar from '../navigation/NavBar';

const ProtectedRoute = () => {
  const { user } = useAuth0();
  const { unhurdUser, accountLoading, userUnauthorized } = useAccount();
  const location = useLocation();
  const navigate = useNavigate();

  const directToOnboarding = useCallback(() => {
    if (location.pathname.includes('paypal-callback')) return;
    if (
      (user && !user.email_verified) ||
      (unhurdUser && unhurdUser.accountConfiguration.onboardingStage < 5) ||
      (user && user.email_verified && !unhurdUser && !accountLoading)
    ) {
      navigate('/onboarding');
      return <Outlet />;
    }
  }, [location.pathname, navigate, unhurdUser, user, accountLoading]);

  useEffect(() => {
    directToOnboarding();
  }, [directToOnboarding, location.pathname]);

  if (accountLoading) {
    return (
      <div className="centered-loading mt48">
        <Loading />
      </div>
    );
  }

  if (userUnauthorized) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="App">
      <TrackPreviewProvider>
        <NavBar />
        <Outlet />
        <Footer />
      </TrackPreviewProvider>
    </div>
  );
};

export default ProtectedRoute;
