import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import AppContext, { SnackbarContextModel } from '@/contexts/AppContext';
import useAccount from '@/hooks/useAccount';
import AuthAPI from '@/network/AuthAPI';
import UserAPI from '@/network/UserAPI';

export interface IPaypalLoginProps {
  page?: string;
  type?: string;
}

export default function PaypalLogin({ page, type }: IPaypalLoginProps) {
  const navigate = useNavigate();
  const { unhurdUser, refetchUser } = useAccount();
  const { dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);

  const response_type = 'code';
  const scope = encodeURIComponent('openid profile email address https://uri.paypal.com/services/paypalattributes');
  let url = encodeURIComponent(`${window.location.origin}/paypal-callback`);

  if (window.location.origin === 'http://localhost:3000') {
    url = encodeURIComponent(`http://127.0.0.1:3000/paypal-callback`);
  }

  const client_id = import.meta.env.VITE_PAYPAL_CLIENT_ID;
  const state = page || 'no-page';

  const openPaypalLogin = async () => {
    if (import.meta.env.VITE_NODE_ENV === 'dev') {
      window.open(
        `https://www.sandbox.paypal.com/connect/?client_id=${client_id}&redirect_uri=${url}&response_type=${response_type}&scope=${scope}&state=${state}`,
        '',
        'popup=true,width=500px,height=800px'
      );
    } else {
      window.open(
        `https://www.paypal.com/connect/?client_id=${client_id}&redirect_uri=${url}&response_type=${response_type}&scope=${scope}&state=${state}`,
        '',
        'popup=true,width=500px,height=800px'
      );
    }
    window.addEventListener('message', (event) => {
      if (!event.data.code) return;
      getPayPalCredentials(event.data.code, event.data.route);
    });
  };

  const getPayPalCredentials = useCallback(
    async (code: string, redirect?: string) => {
      if (!unhurdUser) return;
      const data = {
        code: code,
      };
      try {
        const payPalDetails = await AuthAPI.getPayPalCredentials(data);
        const wallet = {
          payerId: payPalDetails.data.payerId,
          email: payPalDetails.data.email,
          verified: payPalDetails.data.verified,
          name: payPalDetails.data.name,
          verifiedAccount: payPalDetails.data.verifiedAccount,
          emailVerified: payPalDetails.data.emailVerified,
          country: payPalDetails.data.country,
        };
        if (redirect === 'settings') {
          try {
            await UserAPI.updatePaypalDetails({
              auth0Id: unhurdUser?.userId,
              unhurdId: unhurdUser.id,
              data: { paypalDetails: wallet },
            });
            dispatchSnackbar({
              type: 'OPEN_SNACKBAR',
              payload: {
                message: 'PayPal account connected',
                type: 'success',
              },
            });
          } catch {
            dispatchSnackbar({
              type: 'OPEN_SNACKBAR',
              payload: {
                message: 'Error connecting to PayPal',
                type: 'error',
              },
            });
          }
        } else {
          try {
            await UserAPI.updateUserOnboarding({
              auth0Id: unhurdUser?.userId,
              unhurdId: unhurdUser.id,
              data: { onboardingStage: 4, paypalDetails: wallet },
            });
            dispatchSnackbar({
              type: 'OPEN_SNACKBAR',
              payload: {
                message: 'PayPal account connected',
                type: 'success',
              },
            });
          } catch {
            dispatchSnackbar({
              type: 'OPEN_SNACKBAR',
              payload: {
                message: 'Error connecting to PayPal',
                type: 'error',
              },
            });
          }
        }
        await refetchUser();
      } catch (error) {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'Error connecting to PayPal',
            type: 'error',
          },
        });
      } finally {
        if (redirect) {
          navigate(`/${redirect}`, { state: 1 });
        } else {
          navigate('/onboarding');
        }
      }
    },
    [dispatchSnackbar, navigate, refetchUser, unhurdUser]
  );

  useEffect(() => {
    window.removeEventListener('message', () => {
      return;
    });
  }, []);

  return (
    <>
      {type === 'change' ? (
        <Button
          className="text-blue-gradient fw-normal min-w0"
          onClick={() => {
            openPaypalLogin();
          }}
          data-testid="paypal-button-change"
        >
          Change
        </Button>
      ) : (
        <Button
          className="btn-white"
          onClick={() => {
            openPaypalLogin();
          }}
          data-testid="paypal-button"
        >
          Connect your PayPal account
        </Button>
      )}
    </>
  );
}
