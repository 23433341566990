import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';

export const Auth0ProviderWithNavigate = ({ children }: { children: React.ReactNode }) => {
  const audience = 'curator-dashboard-api';

  let domain;

  if (import.meta.env.VITE_NODE_ENV === 'prod') {
    domain = 'unhurd.eu.auth0.com';
  } else {
    domain = 'test-unhurd.uk.auth0.com';
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={`${import.meta.env.VITE_AUTH0_CLIENT_ID}`}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
