import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@mui/material';

import useAccount from '@/hooks/useAccount';

export default function UpdateBioCard() {
  const navigate = useNavigate();
  const { unhurdUser } = useAccount();

  return (
    <>
      {unhurdUser && (!unhurdUser?.bio || unhurdUser.bio === '') && (
        <div
          className="to-do-card-border mt16 cursor-pointer"
          onClick={() => {
            navigate('/settings');
          }}
        >
          <div className="text-center title-card to-do-card d-flex">
            <div className="text-left">
              <h4 data-testid="holiday-mode" className="">
                Add a bio and a vibe to your profile
              </h4>
              <p className="text-faded small">
                Artists are more likely to pitch to curators who have shared a bit about themselves and the playlists
                they curate
              </p>
            </div>
            <Icon className="pos-abs r48 mr16">chevron_right</Icon>
          </div>
        </div>
      )}
    </>
  );
}
