import React from 'react';

export default function Footer() {
  const includedPaths = ['/dashboard'];

  if (!includedPaths.includes(location.pathname)) return null;

  return (
    <>
      <div className="footer">
        <div className="d-flex jc-space-between">
          <div className="">
            <p className="text-faded">Powered by</p>
            <img src="/images/logos/Spotify_Logo_RGB_Green.png" alt="spotify-logo"></img>
          </div>
          <div className=" text-right">
            <a className="text-faded" href="https://www.unhurdmusic.com/partnerterms" target="blank">
              Terms and Conditions
            </a>
            <p className="small text-faded">un:hurd music, Unit 37, Tileyard Road, London, N7 9AH, UK</p>
          </div>
        </div>
      </div>
    </>
  );
}
