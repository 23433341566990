import React from 'react';

import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { dateTimeFormatter } from '../../formatters/DateTimeFormatter';

export interface IAreaChartProps {
  data: unknown[];
}

export default function AreaChartComponent({ data }: IAreaChartProps) {
  const handleTooltip = (e: TooltipProps<number | string | Array<number | string>, string | number>) => {
    if (!e.payload || !e.payload[0]) return;

    return (
      <div className="graph-tooltip text-center">
        <div className="d-flex">
          <div
            style={{
              backgroundImage: 'linear-gradient(255.56deg, #06befe 5.59%, #406bff 96.78%)',
              height: '10px',
              width: '10px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          ></div>
          <p className="small pt0 pl8 text-left">
            {e.payload[0]?.name}: {e.payload[0]?.value}
          </p>
        </div>
        <div className="d-flex">
          <div
            style={{
              backgroundImage: 'linear-gradient(90deg, #f8005e 0%, #f8629b 100%)',
              height: '10px',
              width: '10px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          ></div>
          <p className="small pt0 pl8 text-left">
            {e.payload[1]?.name}: {e.payload[1]?.value}
          </p>
        </div>

        <h4 className="mt8">{dateTimeFormatter(e.label, 'date')}</h4>
      </div>
    );
  };

  return (
    <div data-testid="dashboard-area-chart">
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart className="line-chart" data={data} margin={{ left: -20, right: 20 }}>
          <defs>
            <linearGradient id="blue-gradient-2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#406bff" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#05befe" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="yellow-gradient-2" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#ffae18" stopOpacity={0.5} />
              <stop offset="100%" stopColor="#fdd07a" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="pink-gradient-2" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="5%" stopColor="#f8005e" stopOpacity={0.5} />
              <stop offset="95%" stopColor="#f8629b" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="blue-gradient-1" x1="0" y1="0" x2="1" y2="0">
              <stop offset="5%" stopColor="#406bff" stopOpacity={1} />
              <stop offset="95%" stopColor="#05befe" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="yellow-gradient-1" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#ffae18" stopOpacity={1} />
              <stop offset="100%" stopColor="#fdd07a" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="pink-gradient-1" x1="0" y1="0" x2="1" y2="0">
              <stop offset="5%" stopColor="#f8005e" stopOpacity={1} />
              <stop offset="95%" stopColor="#f8629b" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#383838" vertical={false} />
          <XAxis dataKey="date" tickFormatter={(a: string) => dateTimeFormatter(a, 'date')} />
          <YAxis dataKey="total" allowDecimals={false} />
          <Area
            type="monotone"
            dataKey="accepted"
            name="Accepted"
            strokeOpacity={1}
            strokeWidth={2}
            fillOpacity={0.8}
            stroke="url(#blue-gradient-1)"
            fill="url(#blue-gradient-2)"
          />
          <Area
            type="monotone"
            dataKey="rejected"
            name="Rejected"
            strokeOpacity={1}
            strokeWidth={2}
            fillOpacity={0.8}
            stroke="url(#pink-gradient-1)"
            fill="url(#pink-gradient-2)"
          />

          <Tooltip content={handleTooltip} />
          <Legend verticalAlign="bottom" height={40} iconType="square" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
