import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import { AxiosResponse } from 'axios';
import moment from 'moment';

import useAccount from '@/hooks/useAccount';
import {
  AddPlaylistsAPIModel,
  AddPlaylistsPlaylistsModel,
  Artist,
  SpotifyTrackModel,
  ValidTrackPlacementModel,
} from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';
import UserAPI from '@/network/UserAPI';

import AppContext, { SnackbarContextModel } from '../../contexts/AppContext';
import OnboardingContext, { OnboardingContextModel } from '../../contexts/OnboardingContext';
import { durationFormatter } from '../../formatters/DurationFormatter';
import Loading from '../utility/Loading';

export default function ConnectSpotifyAccount() {
  const { unhurdUser, refetchUser } = useAccount();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [verifyIsLoading, setVerifyIsLoading] = useState<boolean>(false);
  const [accountVerified, setAccountVerified] = useState<boolean>();
  const [verificationStep, setVerificationStep] = useState<number>(0);
  const [spotifyUserId, setSpotifyUserId] = useState<string>('');
  const [spotifyPlaylists, setSpotifyPlaylists] = useState<AddPlaylistsPlaylistsModel[]>([]);
  const [chosenPlaylist, setChosenPlaylist] = useState<AddPlaylistsPlaylistsModel>();
  const [tracks, setTracks] = useState<SpotifyTrackModel[]>([]);

  const { dispatch } = useContext<OnboardingContextModel>(OnboardingContext);
  const { dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);

  const [servedAtTime, setServedAtTime] = useState<string>('');

  const columns: string[] = ['Title', 'Artist', 'Album', 'Duration', ''];

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const updateUser = async () => {
    if (!unhurdUser) return;
    setIsLoading(true);
    const details = {
      userId: unhurdUser?.userId,
      id: unhurdUser?.id,
      spotifyId: spotifyUserId,
      onboardingStage: 2,
    };

    UserAPI.updateUserOnboarding({ auth0Id: unhurdUser?.userId, unhurdId: unhurdUser?.id, data: details }).then(() => {
      localStorage.setItem('sp-id', spotifyUserId);
      refetchUser();
      dispatch({
        type: 'PAGE NEXT',
      });
      setIsLoading(false);
    });
  };

  const checkIfSpotifyExists = async () => {
    setIsLoading(true);
    try {
      const data = await UserAPI.checkSpotifyAccountExists({ id: spotifyUserId });
      if (data.data.curatorConnected) {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'This Spotify account is already connected to another un:hurd music curator account',
            type: 'error',
          },
        });
      } else {
        await getUserPlaylists();
      }
    } catch (error) {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: "We can't find a Spotify account with this username, double check and try again",
          type: 'error',
        },
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const formUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpotifyUserId(event.target.value);
  };

  const getUserPlaylists = async () => {
    if (!spotifyUserId) return;
    setIsLoading(true);
    await PlaylistAPI.getUserSpotifyPlaylists({ id: spotifyUserId })
      .then((resp: AxiosResponse<AddPlaylistsAPIModel>) => {
        setSpotifyPlaylists(resp.data.playlists);
        setChosenPlaylist(resp.data.playlists[0]);
        setIsLoading(false);
      })
      .catch(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: "We can't find any playlists connected to this username, double check and try again.",
            type: 'error',
          },
        });
        setVerificationStep(0);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (spotifyPlaylists && spotifyPlaylists.length > 0) {
      setChosenPlaylist(spotifyPlaylists[0]);
    }
  }, [spotifyPlaylists]);

  const handlePositionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const playlist = spotifyPlaylists.find((item) => item.spotifyId === event.target.value);
    setChosenPlaylist(playlist);
  };

  const verifyAccount = () => {
    if (!chosenPlaylist || !tracks || !tracks[0]) return;
    setVerifyIsLoading(true);
    PlaylistAPI.verifyTrackPlaylistPlacement({
      playlistId: chosenPlaylist.spotifyId,
      trackId: tracks[0]?.id,
      servedAt: servedAtTime,
    })
      .then((resp: AxiosResponse<ValidTrackPlacementModel>) => {
        if (resp.data.validTrackPlacement) {
          setVerificationStep(2);
          setAccountVerified(true);
        } else {
          dispatchSnackbar({
            type: 'OPEN_SNACKBAR',
            payload: {
              message: 'Please add the track to your playlist to verify your account',
              type: '',
            },
          });
        }
        setVerifyIsLoading(false);
      })
      .catch((err) => {
        if (err.response.data.statusCode === 400) {
          dispatchSnackbar({
            type: 'OPEN_SNACKBAR',
            payload: {
              message: err.response.data.errorMessage,
              type: 'error',
            },
          });
        }
        setVerifyIsLoading(false);
        getTrackForPlaylist();
      });
  };

  const getTrackForPlaylist = () => {
    if (!chosenPlaylist) return;

    setIsLoading(true);
    PlaylistAPI.getTrackForPlaylistPlacement({ id: chosenPlaylist.spotifyId })
      .then((resp: AxiosResponse<SpotifyTrackModel>) => {
        setTracks([resp.data]);
        const date = moment().utc().format('MM/DD/YY HH:mm:ss');
        setServedAtTime(date);
        setVerificationStep(1);
        setIsLoading(false);
      })
      .catch((err) => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: err.response.data.errorMessage,
            type: 'error',
          },
        });
        setIsLoading(false);
      });
  };

  return (
    <div>
      <h1 className="">Connect Your Spotify Account</h1>

      {verificationStep === 0 && !isLoading && (
        <div>
          <h3 className="text-faded mt32">
            Connect your Spotify account to select from your playlists that you wish to be pitched to.
          </h3>

          {/* Enter Username */}
          <div className="top-cards jc-center mt32">
            <div className="title-card max-w400">
              <div className="card-title">
                <div className="titles">
                  <h4>Enter your Spotify username</h4>
                  <Tooltip
                    title={
                      <div className="d-flex flex-d-col connect-spotify-tooltip">
                        <h3>How to find your Spotify username</h3>
                        <img className="mt16" src="/images/spotify-account-info.png" alt="spotify-logo"></img>
                        <p className="mt16">
                          1.{' '}
                          <a href="https://www.spotify.com/uk/account/profile/" target="blank">
                            Go to your account on Spotify
                          </a>
                        </p>
                        <p>2. Select & copy your username</p>
                        <p>3. Paste in the field below</p>
                      </div>
                    }
                    // placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#383838',
                          width: 'fit-content',
                          maxWidth: 'none',
                          borderRadius: '8px',
                        },
                      },
                    }}
                  >
                    <p className="small text-blue-gradient cursor-pointer text-left">Where can I find this?</p>
                  </Tooltip>
                </div>
              </div>
              <label>
                <p className="small mt16">Spotify username</p>
                <input
                  placeholder="Enter your Spotify username"
                  name="userid"
                  onChange={formUpdate}
                  value={spotifyUserId}
                  data-testid="spotify-userid-input"
                ></input>
              </label>
              {spotifyPlaylists && spotifyPlaylists.length > 0 && (
                <label>
                  <p className="small mt16">Select one of your current playlists</p>
                  <p className="small text-faded mt-6 mb16">
                    In order to verify your account, we need you to add a track of our choosing to one of your existing
                    playlists. You can also make a new playlist for this and delete it after.
                  </p>
                  <FormControl className="mt16">
                    <InputLabel id="playlist-track-select-label">Select a playlist</InputLabel>
                    <Select
                      value={chosenPlaylist?.spotifyId}
                      label="Track Position"
                      onChange={(event: unknown) => {
                        handlePositionChange(event as React.ChangeEvent<HTMLSelectElement>);
                      }}
                    >
                      {spotifyPlaylists.map((item: AddPlaylistsPlaylistsModel, index: number) => (
                        <MenuItem key={index} value={item.spotifyId}>
                          <div className="table-item-with-image">
                            <img
                              src={item.image?.url ? item.image?.url : '/images/logos/no-image-available.svg'}
                              alt=""
                            />
                            <div>
                              <p className="small">{item?.name}</p>
                            </div>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </label>
              )}
              {spotifyPlaylists && spotifyPlaylists.length > 0 ? (
                <div className="d-flex">
                  <Button
                    className="mt32 border-btn w50p ml0"
                    disabled={spotifyUserId === ''}
                    onClick={() => {
                      getUserPlaylists();
                    }}
                    data-testid="submit-spotify-data"
                  >
                    <Icon>refresh</Icon>
                    <span className="btn-text icon-prefix pl8">Refresh playlists</span>
                  </Button>
                  <Button
                    className="mt32 btn-white w50p ml0"
                    disabled={spotifyUserId === ''}
                    onClick={() => {
                      getTrackForPlaylist();
                    }}
                    data-testid="submit-spotify-data"
                  >
                    <span className="btn-text">Continue</span>
                  </Button>
                </div>
              ) : (
                <Button
                  className="mt32 btn-white w100p ml0"
                  disabled={spotifyUserId === ''}
                  onClick={() => {
                    checkIfSpotifyExists();
                  }}
                  data-testid="submit-spotify-data"
                >
                  <span className="btn-text">Continue</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Add tracks to playlist & verify */}
      {verificationStep === 1 && !isLoading && (
        <div className="mt32">
          <h3 className="text-faded">
            In order to verify your account, we need you to add a track of our choosing to one of your playlists.
          </h3>
          <div className="choose-track-to-add-card">
            <div className="card-title">
              <div className="titles">
                <h4 className="text-left">Add this song to your selected playlist</h4>
                <p className="text-left small text-faded">
                  Selected playlist: <span className="text-white">{chosenPlaylist?.name}</span>
                </p>
              </div>
            </div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="10%" />
                  <col width="20%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tracks?.map((row: SpotifyTrackModel, index: number) => (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '--animation-number': index % 20,
                      }}
                      key={index}
                    >
                      <TableCell>
                        <div className="table-item-with-image">
                          <img
                            src={
                              row.album?.images.length > 0
                                ? row.album?.images[row.album?.images.length - 1].url
                                : 'images/logos/no-image-available.svg'
                            }
                            alt=""
                          />
                          <span>{row.name}</span>
                        </div>
                      </TableCell>
                      <TableCell width="20%">
                        {row.artists?.map((artist: Artist, index: number) =>
                          index === row.artists.length - 1 ? artist?.name : artist?.name + ', '
                        ) || 'N/A'}
                      </TableCell>
                      <TableCell>{row.album?.name || 'N/A'}</TableCell>
                      <TableCell>{durationFormatter(row.durationMs, 'msToMM:SS')}</TableCell>
                      <TableCell>
                        <div className="table-end-call-with-button">
                          <Tooltip title="View on Spotify" arrow placement="top">
                            <Button
                              className="border-btn ml-auto"
                              onClick={() => {
                                window.open(row.externalUrls.spotify);
                              }}
                            >
                              <span className="btn-text icon-suffix">Add track on Spotify</span>
                              <img className="material-icons pl8" src="/images/spotify-logo.png" alt="spot-logo"></img>
                            </Button>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            className="mt32 "
            onClick={() => {
              setVerificationStep(0);
            }}
            data-testid="submit-spotify-data"
          >
            <Icon>chevron_left</Icon>
            <span className="btn-text icon-prefix pl8">Back</span>
          </Button>{' '}
          <Button
            className="mt32 btn-white"
            disabled={verifyIsLoading}
            onClick={() => {
              verifyAccount();
            }}
            data-testid="submit-spotify-data"
          >
            <span className="btn-text">{verifyIsLoading ? <CircularProgress size={16} /> : 'Verify my account'}</span>
          </Button>{' '}
        </div>
      )}

      {verificationStep === 2 && !isLoading && (
        <div className="mt48">
          <img className="verified-account-image" src="/images/icons/VerifiedIcon.svg" alt="" />
          <h3>Great! We've verified your Spotify account</h3>
          <p className="text-faded mt16">Feel free to now remove the track and/or playlist used for verification</p>
        </div>
      )}

      {isLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {accountVerified && verificationStep === 2 && (
        <div className="onboarding-nav-container">
          <div className="onboarding-nav-buttons">
            <div className="ml-auto">
              <Button
                className="mt32 btn-white"
                disabled={isLoading}
                onClick={() => {
                  updateUser();
                }}
                data-testid="submit-spotify-data"
              >
                <span className="btn-text icon-suffix">Continue</span>
                <Icon>chevron_right</Icon>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
