import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, CircularProgress, Dialog, Switch } from '@mui/material';

import AppContext, { SnackbarContextModel } from '@/contexts/AppContext';
import useAllPitches from '@/hooks/pitches/useAllPitches';
import usePlaylistPitches from '@/hooks/pitches/usePlaylistPitches';
import useAreAllPlaylistsPaused from '@/hooks/playlists/useAreAllPlaylistsPaused';
import usePlaylist from '@/hooks/playlists/usePlaylist';
import useAccount from '@/hooks/useAccount';
import { UnhurdPlaylist } from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';

interface IHolidayModeProps {
  playlist?: UnhurdPlaylist;
  isAccountMode?: boolean;
}

export default function HolidayMode({ playlist, isAccountMode }: IHolidayModeProps) {
  const navigate = useNavigate();
  const [isHolidayMode, setIsHolidayMode] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [hasOutstandingPitches, setHasOutstandingPitches] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);
  const { refetchPlaylist } = usePlaylist({
    playlistId: playlist?.id || '',
  });
  const { unhurdUser } = useAccount();
  const { areAllPlaylistsPaused, areAllPlaylistsPausedIsLoading, refetchAreAllPlaylistsPaused } =
    useAreAllPlaylistsPaused();

  const { totalPitches } = useAllPitches({
    status: 'pending',
  });

  const { totalPlaylistPitches } = usePlaylistPitches({
    playlistId: playlist?.platform.id || '',
    status: 'pending',
  });

  const toggleSwitch = async () => {
    if (!isHolidayMode) {
      setDialogOpen(true);
    } else {
      if (isAccountMode) {
        await pauseAccountPlaylists();
      } else {
        await pausePlaylist();
        await refetchPlaylist();
      }
      setIsHolidayMode(!isHolidayMode);
    }
    refetchAreAllPlaylistsPaused();
  };

  const pausePlaylist = async () => {
    await PlaylistAPI.pausePlaylist({
      id: playlist?.platform.id || '',
      data: {
        isPaused: !isHolidayMode,
      },
    })
      .then(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: `Holiday mode ${isHolidayMode ? 'deactivated' : 'activated'}`,
            type: 'success',
          },
        });
        setIsHolidayMode(true);
        setDialogOpen(false);
      })
      .catch((err) => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: `Failed to ${isHolidayMode ? 'deactivate' : 'activate'} holiday mode. ${err.response.data.errorMessage}`,
            type: 'error',
          },
        });
      })
      .finally(() => {
        refetchPlaylist();
        setIsLoading(false);
      });
  };

  const pauseAccountPlaylists = async () => {
    await PlaylistAPI.pauseAllPlaylists({
      id: unhurdUser?.spotifyAccounts[0].id || '',
      data: {
        isPaused: !isHolidayMode,
      },
    })
      .then(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: `Holiday mode ${isHolidayMode ? 'deactivated' : 'activated'}`,
            type: 'success',
          },
        });
        setIsHolidayMode(true);
        setDialogOpen(false);
      })
      .catch((err) => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: `Failed to ${isHolidayMode ? 'deactivate' : 'activate'} holiday mode. ${err.response.data.errorMessage}`,
            type: 'error',
          },
        });
      })
      .finally(() => {
        refetchAreAllPlaylistsPaused();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isAccountMode || !totalPitches) return;
    if (totalPitches > 0) {
      setHasOutstandingPitches(true);
    } else {
      setHasOutstandingPitches(false);
    }
  }, [isAccountMode, totalPitches]);

  useEffect(() => {
    if (isAccountMode || !totalPlaylistPitches) return;
    if (totalPlaylistPitches > 0) {
      setHasOutstandingPitches(true);
    } else {
      setHasOutstandingPitches(false);
    }
  }, [isAccountMode, totalPlaylistPitches]);

  useEffect(() => {
    if (!areAllPlaylistsPaused || !isAccountMode) return;
    setIsHolidayMode(areAllPlaylistsPaused.allPaused);
  }, [areAllPlaylistsPaused, isAccountMode]);

  useEffect(() => {
    if (!playlist) return;
    setIsHolidayMode(playlist.statusInfo.isPaused);
  }, [playlist]);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        className="max-w500 ml-auto mr-auto"
      >
        <h3>Holiday mode</h3>
        <p className="mt16 text-faded">
          When you activate holiday mode, submissions to{' '}
          <span className="text-white">{isAccountMode ? 'all of your playlists' : 'this playlist'}</span> will be
          paused. You can turn holiday mode off when you’re ready to receive pitches again.
          {!isAccountMode && (
            <>
              {' '}
              If you would like to pause all playlist submissions, you can do this in{' '}
              <span
                className="text-white cursor-pointer"
                onClick={() => {
                  navigate('/settings');
                }}
              >
                settings
              </span>
              .
            </>
          )}
        </p>
        <p className="mt16 text-faded">
          If a playlist remains in holiday mode for more than 6 months, it will be re-reviewed before we can activate it
          again.
        </p>
        {hasOutstandingPitches && (
          <p className="mt16 fw-bold">
            You must accept or reject all of the pending pitches for{' '}
            {isAccountMode ? 'all of your playlists' : 'this playlist'} first to be able to activate holiday mode.
          </p>
        )}
        <div className="mt16">
          {hasOutstandingPitches && (
            <Button
              className="border-btn w100p ml0"
              onClick={() => {
                if (isAccountMode) {
                  navigate(`/dashboard/pendingpitches`);
                } else {
                  return setDialogOpen(false);
                }
                setIsHolidayMode(false);
                setDialogOpen(false);
              }}
            >
              View outstanding pitches
            </Button>
          )}
          <Button
            className="btn-white w100p ml0"
            disabled={hasOutstandingPitches}
            onClick={() => {
              setIsLoading(true);
              if (isAccountMode) return pauseAccountPlaylists();
              return pausePlaylist();
            }}
          >
            {isLoading ? <CircularProgress size={16} /> : 'Activate holiday mode'}
          </Button>
        </div>
      </Dialog>
      <div className={`d-flex ${isAccountMode ? 'w100p mt16' : ''}`}>
        <p className="mt-auto mb-auto">Holiday mode: </p>
        {((isAccountMode && !areAllPlaylistsPausedIsLoading) || (!isAccountMode && !isLoading)) && (
          <Switch
            className={`cursor-pointer ${isHolidayMode ? 'selected' : ''} ${isAccountMode ? 'ml-auto' : ''}`}
            checked={isHolidayMode}
            onClick={toggleSwitch}
          />
        )}
        {(isLoading || areAllPlaylistsPausedIsLoading) && <CircularProgress className="ml-auto" size={16} />}
      </div>
    </>
  );
}
