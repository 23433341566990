import React, { useCallback, useContext, useReducer, useState } from 'react';

import { useAuth0, User } from '@auth0/auth0-react';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Icon from '@mui/material/Icon';

import AppContext, { SnackbarContextModel } from '@/contexts/AppContext';
import useAccount from '@/hooks/useAccount';
import UserAPI from '@/network/UserAPI';
import { FormState } from '@/pages/SettingsPage';

import OnboardingContext, { OnboardingContextModel } from '../../contexts/OnboardingContext';

export default function UserInfo() {
  const { user } = useAuth0();
  const { unhurdUser } = useAccount();

  const { dispatch } = useContext<OnboardingContextModel>(OnboardingContext);
  const { dispatchSnackbar } = useContext<SnackbarContextModel>(AppContext);

  const initialFormState = {
    firstname: user?.given_name || '',
    lastname: user?.family_name || '',
    email: user?.email || '',
    bio: '',
    vibe: '',
    businessName: '',
  };

  type FormAction = {
    type: string;
    field: string;
    payload: string;
  };

  const formReducer = (state: FormState, action: FormAction): FormState => {
    switch (action.type) {
      case 'HANDLE INPUT TEXT':
        return {
          ...state,
          [action.field]: action.payload,
        };
      default:
        return state;
    }
  };

  const [formState, dispatchForm] = useReducer(formReducer, initialFormState);
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [isSendingForm, setIsSendingForm] = useState<boolean>(false);

  const formUpdate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatchForm({
      type: 'HANDLE INPUT TEXT',
      field: event.target.name,
      payload: event.target.value,
    });
  };

  const updateUser = useCallback(
    async (user: User) => {
      if (formState.bio.length < 100) {
        setIsSendingForm(false);

        return dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'Bio must be at least 100 characters long',
            type: 'error',
          },
        });
      }
      const details = {
        userId: user?.userId,
        id: user?.id,
        firstName: formState.firstname,
        lastName: formState.lastname,
        businessName: formState.businessName,
        bio: formState.bio,
        vibe: formState.vibe,
        email: formState.email,
        onboardingStage: 1,
      };

      await UserAPI.updateUserOnboarding({ auth0Id: user?.userId, unhurdId: user?.id, data: details })
        .then(() => {
          dispatch({
            type: 'PAGE NEXT',
          });
          setIsSendingForm(false);
        })
        .catch(() => {
          setIsSendingForm(false);
        });
    },
    [
      dispatch,
      dispatchSnackbar,
      formState.bio,
      formState.businessName,
      formState.email,
      formState.firstname,
      formState.lastname,
      formState.vibe,
    ]
  );

  const createUser = useCallback(async () => {
    if (!user || !user.sub || !user.email) return;
    if (formState.bio.length < 100) {
      setIsSendingForm(false);

      return dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: 'Bio must be at least 100 characters long',
          type: 'error',
        },
      });
    }
    const data = {
      userId: user.sub,
      authenticationType: 0,
      email: user.email,
      bio: formState.bio || '',
      vibe: formState.vibe || '',
      firstName: formState.firstname || '',
      lastName: formState.lastname || '',
    };
    await UserAPI.createUser(data).then(async (resp) => {
      await updateUser(resp.data);
    });
  }, [dispatchSnackbar, formState.bio, formState.firstname, formState.lastname, formState.vibe, updateUser, user]);

  const submitForm = async () => {
    setIsSendingForm(true);
    if (!unhurdUser) return createUser();
    if (formInvalid()) return setIsSendingForm(false);
    updateUser(unhurdUser);
  };

  const formInvalid = () => {
    if (formState.firstname !== '' && formState.lastname !== '' && formState.bio !== '' && isTermsChecked) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChange = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  return (
    <div>
      <h1 className="">Hi, tell us a little bit about yourself</h1>
      <h3 className="text-faded mt32">
        We’re glad you’re here, we just need to know a little bit about you before we can get started.{' '}
      </h3>
      <div className="user-info-form onboarding">
        <form>
          <div className="user-form-names">
            <label>
              <p>First name *</p>
              <input
                placeholder="John"
                name="firstname"
                value={formState.firstname}
                onChange={formUpdate}
                data-testid="firstname"
                required
              ></input>
            </label>
            <label>
              <p>Last name *</p>
              <input
                placeholder="Smith"
                name="lastname"
                value={formState.lastname}
                onChange={formUpdate}
                data-testid="lastname"
                required
              ></input>
            </label>
          </div>
          <label>
            <p>Business name</p>
            <input
              placeholder="[Example] un:hurd music"
              name="businessName"
              value={formState.businessName}
              onChange={formUpdate}
            ></input>
          </label>
          <label>
            <p className="mt16">Bio *</p>
            <div className="pos-rel">
              <textarea
                placeholder="Tell us a bit about yourself - what inspired you to create your playlists, what you love about music, etc. Artists are more likely to pitch to curators with a bio."
                name="bio"
                value={formState.bio}
                maxLength={500}
                onChange={formUpdate}
                data-testid="bio-input"
              ></textarea>
              <div className="textarea-counter">(minimum 100 characters) {formState.bio?.length || 0}/500</div>
            </div>
          </label>
          <label>
            <p className="mt16">Vibe</p>
            <div className="pos-rel">
              <textarea
                placeholder="[Example] Fresh cuts from indie, electronic, and soulful R&B"
                name="vibe"
                maxLength={100}
                value={formState.vibe}
                onChange={formUpdate}
                className="vibe-textarea"
                data-testid="vibe-input"
              ></textarea>
              <div className="textarea-counter">{formState.vibe?.length || 0}/100</div>
            </div>
          </label>
          <label>
            <p className="mt16">Email address</p>
            <input
              placeholder="Enter email address"
              name="email"
              disabled={true}
              value={formState.email}
              onChange={formUpdate}
            ></input>
          </label>
          <FormControlLabel
            className="mt16"
            label={
              <p className="pt8">
                Click here to agree to our{' '}
                <span>
                  <a href="https://www.unhurdmusic.com/partnerterms" target="blank">
                    Terms & Conditions
                  </a>
                </span>
              </p>
            }
            control={
              <Checkbox
                id="playlist-checkbox"
                data-testid="ts-and-cs-checkbox"
                name="playlist-checkbox"
                icon={<div className="checkbox-icon"></div>}
                checkedIcon={
                  <div className="checkbox-icon">
                    <div className="checked"></div>
                  </div>
                }
                onChange={() => {
                  handleCheckboxChange();
                }}
                value={false}
              />
            }
          />
        </form>
      </div>
      <div className="onboarding-nav-container">
        <div className="onboarding-nav-buttons">
          <div className="ml-auto">
            <Button
              disabled={formInvalid() || isSendingForm}
              className="mt32 btn-white"
              onClick={() => {
                submitForm();
              }}
              data-testid="submit-user-data"
            >
              <span className="btn-text icon-suffix">Continue</span>
              <Icon>chevron_right</Icon>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
