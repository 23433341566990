import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@mui/material';

import useAreAllPlaylistsPaused from '@/hooks/playlists/useAreAllPlaylistsPaused';

export default function HolidayModeCard() {
  const navigate = useNavigate();
  const { areAllPlaylistsPaused, areAllPlaylistsPausedIsLoading } = useAreAllPlaylistsPaused();

  return (
    <>
      {!areAllPlaylistsPausedIsLoading && areAllPlaylistsPaused?.allPaused && (
        <div
          className="to-do-card-border mt16 cursor-pointer"
          onClick={() => {
            navigate('/settings');
          }}
        >
          <div className="text-center title-card to-do-card d-flex">
            <h4 data-testid="holiday-mode" className="">
              Turn off holiday mode 🏝️ to start receiving pitches to your playlists again
            </h4>
            <Icon className="pos-abs r48 mr16">chevron_right</Icon>
          </div>
        </div>
      )}
    </>
  );
}
